import TemplateValidation from "../../backoffice/utils/TemplateValidation";

export default class ExtranetIncidentsNew {

    constructor() {
        this.templateValidation = null;
        this.service_select = null;
        this.criticality_select = null;
        this.category_select = null;
        this.environment_select = null;
        this.dropify = null;
        this.formValidated = false;

        this.bindEvents();
        this.initValidation();
        this.initSelect2();
        this.initDropify();
    }

    bindEvents() {
        flatpickr('#started_at', {
            locale: 'fr',
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            onChange: function(selectedDates, dateStr, instance) {
                $("#started_at_i").val(dateStr);
            }
        });

        $('#criticality').on('change', () => {
            this.updateValidationRules();

            if ($('#criticality').val().toLowerCase() === 'mineur' || $('#criticality').val().toLowerCase() === 'minor') {
              $('#started_at_asterix').hide();
            } else {
              $('#started_at_asterix').show();
            }
        });

        $('#incident_form').on('submit', () => {
            setTimeout(() => {
                $('#started_at_i-error').appendTo('#started_at-error-container');
            }, 10);
            this.formValidated = true;
        });

        if ($('#criticality').val().toLowerCase() === 'mineur' || $('#criticality').val().toLowerCase() === 'minor') {
          $('#started_at_asterix').hide();
        } else {
          $('#started_at_asterix').show();
        }
        
        this.updateCategories();

        // Add event listener for service_id change
        $('#service_id').on('change', () => {
          this.updateCategories();
        });
    }

    updateCategories() {
      const serviceName = $('#service_id option:selected').text();

      if (serviceName) {
          console.log('Service name:', serviceName);
          $("#category").prop('disabled', false);

          $.ajax({
              url: '/incidents/get_categories_for_service_ajax',
              method: 'GET',
              data: { service_name: serviceName },
              success: (data) => {
                  const categorySelect = $('#category');
                  categorySelect.empty();
                  categorySelect.append('<option></option>');
                  data.forEach((category) => {
                      categorySelect.append(`<option value="${category.value}">${category.name}</option>`);
                  });
                  categorySelect.selectpicker('refresh');
              },
              error: (xhr, status, error) => {
                  console.error('Failed to fetch categories:', error);
              }
          });
      } else {
          $('#category').prop('disabled', true);
          $("#category").empty();
          $('#category').selectpicker('refresh');
      }
    }

    onDataChanged(data) {
    }

    onDestroy() {
        if(this.templateValidation !== null){
            this.templateValidation.onDestroy();
            this.templateValidation = null;
        }
        this.service_select.selectpicker('destroy');
        this.criticality_select.selectpicker('destroy');
        this.category_select.selectpicker('destroy');
        this.environment_select.selectpicker('destroy');
        if (this.dropify) {
            this.dropify.destroy();
        }
    }

    initValidation() {
        this.templateValidation = new TemplateValidation(this.validationRules(), '#incident_form');
    }

    validationRules() {
        const criticalityValue = $('#criticality').val().toLowerCase();
        const startedAtRequired = (criticalityValue != 'mineur' && criticalityValue != 'minor');

        return {
            'service_id': {
                required: true,
            },
            'category': {
                required: true,
            },
            'criticality': {
                required: true,
            },
            'environment': {
                required: true,
            },
            'contact_phone': {
                required: true,
                phone_number_fr: true,
            },
            'contact_email': {
                required: true,
                email_validation: true,
            },
            'description': {
                required: true,
                maxlength: 100,
                minlength: 5
            },
            'long_description': {
                required: true,
                maxlength: 512,
                minlength: 20
            },
            'started_at': {
                required: startedAtRequired,
            }
        }
    }

    updateValidationRules() {
        if (this.templateValidation) {
            this.templateValidation.onDestroy();
        }
        this.templateValidation = new TemplateValidation(this.validationRules(), '#incident_form');
        
        if (this.formValidated) {
            $('#incident_form').valid();
        }
    }

    initSelect2() {
        this.service_select = $('#service_id').selectpicker();
        this.criticality_select = $('#criticality').selectpicker();
        this.category_select = $('#category').selectpicker();
        this.environment_select = $('#environment').selectpicker();
    }

    initDropify() {
        this.dropify = $('.dropify').dropify();
    }
}